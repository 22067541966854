@import '../../assets/scss/variables';

.modal {
  position: fixed;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  z-index: 1000;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  padding: 24px;
  gap: 20px;

  &.normal {
    max-width: 512px;
    min-height: 480px;
  }

  &.small {
    max-width: 512px;
    min-height: 256px;
  }
}

.content {
  flex: 1;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 8px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.overlayBg {
  background-color: rgba(0, 0, 0, 0.5);
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
