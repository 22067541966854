@import '../../../../../../innova-components/src/lib/assets/scss/_variables.scss';
@import '../../assets/styles/variables';

.background {
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  margin-top: 8px;
}

.sliderComplete {
  > div {
    > div {
      align-items: center;
    }
  }
}

.sliderScroll {
  > div {
    overflow-x: scroll;
  }
}

.slider {
  > div {
    > div {
      display: flex;
      margin: 48px 84px;

      @media screen and (max-width: 1280px) {
        margin: 32px 84px;
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.slick-slider {
  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
    }
  }
}

.slick-list {
  height: auto;
  padding-top: 12px;
  padding-bottom: 16px;
  overflow: visible;
}

.slick-prev,
.slick-next {
  opacity: 0;
  transition: 0.15s all;
  position: absolute;
  top: 25%;
  z-index: 10;

  // @media screen and (max-width: $burgerMenuBreakpoint) {
  //   opacity: 1;
  // }
}

.slick-prev {
  left: 0;
  margin-left: -24px;
}

.slick-next {
  right: 0;
  margin-right: -8px;
}

.slick-disabled {
  display: none;
  pointer-events: none;
}

.slick-track {
  margin: 0;

  // remove padding-right of last session on the list, className is resourceBox in case you want to search for it
  > div:last-child {
    > div {
      > div {
        > div {
          padding-right: 24px;

          > div {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.showSlider {
  .slick-slide:not(.slick-active) {
    opacity: 0.25;
    pointer-events: none;
  }
}

.showMoreSlider {
  .slick-slide:not(.slick-active) {
    opacity: 0;
  }
}

.activityDivisor {
  align-items: center;
  display: flex !important;
  flex-direction: column;
  gap: 32px;
}

.activityContainer {
  align-items: center;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 4px !important;

  width: 90%;

  img {
    aspect-ratio: 16 / 9;
  }
}

.activityContainer::before {
  content: '';
  display: block;
  float: left;
  padding-top: 50.6%;
}

.selectableActivity {
  &:hover {
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
  }
}

.currentActivityContainer {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24);
  margin-top: 0;
  transform: scale(1.05);

  &:hover {
    transform: scale(1.06);
  }
}

.lineContainer {
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
}

.circle {
  align-items: center;
  border: none;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;

  svg {
    path {
      fill: $primary;
      stroke: $primary;
    }
  }
}

.fixMarginTop {
  margin-top: 14px;

  @media screen and (max-width: 1920px) {
    margin-top: 12px;
  }
}

.clickEffect {
  cursor: pointer;

  &:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.connectorLine {
  backdrop-filter: blur(4px);
  border: 3px solid rgba(255, 255, 255, 0);
  height: 0.12px;
  width: 100%;

  @media screen and (max-width: 1920px) {
    border: 2px solid rgba(255, 255, 255, 0);
  }
}

.rightLine {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.leftLine {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.hidden {
  visibility: hidden;
}

.miniCircle {
  height: 32px;
  width: 32px;

  > div {
    height: 16px !important;
    width: 16px !important;
  }

  @media screen and (max-width: 1920px) {
    height: 24px;
    width: 24px;

    > div {
      height: 12px !important;
      width: 12px !important;
    }
  }
}

.bigCircle {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  height: 60px;
  width: 60px;

  > div {
    height: 24px !important;
    margin-left: 6px;
    width: 24px !important;
  }

  @media screen and (max-width: 1920px) {
    height: 48px;
    width: 48px;

    > div {
      height: 20px !important;
      margin-left: 6px;
      width: 20px !important;
    }
  }
}

.checkIcon {
  margin-left: 0 !important;
}

.startBlur {
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.75);
}

.completedBlur {
  background: white;
}

.inactiveBlur {
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.5);
}
