@import "../../assets/scss/variables";

.drawer {
  position: fixed;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;
  box-shadow: $elevation2;

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }
}

.content {
  flex: 1;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 8px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.08);
  z-index: 1000;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
