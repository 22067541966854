.avatarGroup {
  display: flex;

  .avatar {
    border: 2px solid white;
  }
}

.clickable {
  cursor: pointer;
}

.overlap {
  div {
    margin-left: 0;

    &:not(:first-child) {
      margin-left: -20px;
    }
  }
}