@font-face {
  font-family: SegoeUI;
  font-weight: 400;
  src:
    local('Segoe UI'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff2') format('woff2'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.woff') format('woff'),
    url('//c.s-microsoft.com/static/fonts/segoe-ui/west-european/normal/latest.ttf') format('truetype');
}

@font-face {
  font-family: Bariol;
  font-style: normal;
  font-weight: normal;
  src: local('Bariol Regular'), url('../fonts/bariol_regular-font.woff') format('woff');
}

@font-face {
  font-family: 'Bariol Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Bariol Bold'), url('../fonts/bariol_bold-font.woff') format('woff');
}

@font-face {
  font-family: "Rubik Medium";
  font-weight: 500;
  font-style: normal;
  src: local("Rubik Medium"), local("Rubik-Medium"), url('../fonts/rubikMedium.ttf') format("truetype");
}

@font-face {
  font-family: "Rubik";
  font-weight: 400;
  font-style: normal;
  src: local("Rubik"), local("Rubik-Regular"), url('../fonts/rubikRegular.ttf') format("truetype");
}

@font-face {
  font-family: "Rubik SemiBold";
  font-weight: 600;
  font-style: normal;
  src: local("Rubik SemiBold"), local("Rubik-SemiBold"), url('../fonts/rubikSemiBold.ttf') format("truetype");
}
