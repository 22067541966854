@import '../../assets/scss/variables';

.notification {
  padding: 12px 8px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &.info {
    background-color: $semantic01b;
  }

  &.warning {
    background-color: $semantic02b;
  }

  &.error {
    background-color: $semantic03b;
  }

  .closeIcon {
    cursor: pointer;
  }

  span {
    font-size: $xsmall;
    line-height: 16px;
  }
}
