@import '../../assets/scss/variables';

.container {
  display: flex;
  gap: 16px;
  cursor: pointer;
}

.infoContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  .h5 {
    display: flex;
    color: $black;
  }
}

.courseM {
  display: flex;
  color: $dark02;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
}

.courseL {
  display: flex;
  color: $dark03;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.courseS {
  display: flex;
  color: $dark02;
  font-size: 12px;
  line-height: 14px;
  margin-top: 4px;
}
