.toolBar {
  background-color: #ccc;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  box-sizing: content-box;
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: space-around;
  left: 25%;
  padding: 7px;
  position: fixed;
  top: calc(var(--vh, 1vh) * 70);
  width: 430px;

  svg,
  path {
    stroke: #333;
  }

  input {
    border: none;
    border-radius: 5px;
    font-size: 16px;
    height: 30px;
    padding: 0 8px;
    text-align: center;
    width: 56px;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
}

.toolbarButton {
  background-color: transparent;
  border: none;
  border-radius: 5px;
  height: 30px;
  margin: 0 4px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #949494;
    cursor: pointer;
  }

  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
  }
}

.separator {
  background-color: white;
  height: 30px;
  margin: 0 4px;
  width: 1px;
}

.dragButton {
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-right: 6px;
  width: 30px;

  &:hover {
    cursor: move;
  }
}

.disabled {
  &:disabled {
    background-color: white;
  }
}