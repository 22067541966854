@import 'normalize';
@import 'variables';

body {
  color: $black;
  font-family: $roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  color: $black;
  font-family: $roboto;
}

input,
textarea {
  line-height: 24px;
}

a {
  text-decoration: none;
  color: $primary;
}

h1 {
  font-size: $xlarge;
  font-weight: 400;
  line-height: 56px;
}

h2 {
  font-size: $large;
  font-weight: 400;
  line-height: 36px;
}

h3 {
  font-size: $caption;
  font-weight: 400;
  line-height: 28px;
}

h4,
.h4 {
  font-size: $medium;
  font-weight: 400;
  line-height: 24px;
}

h5,
.h5 {
  font-family: $robotoMedium;
  font-size: $normal;
  font-weight: 500;
  line-height: 20px;
}

.h6 {
  font-family: $robotoMedium;
  font-size: $small;
  font-weight: 500;
  line-height: 16px;
}

.h7 {
  font-family: $robotoMedium;
  font-size: $xsmall;
  font-weight: 500;
  line-height: 14px;
}

.h8 {
  font-family: $robotoMedium;
  font-size: $micro;
  font-weight: 500;
  line-height: 12px;
}

label {
  font-size: 14px;
  line-height: 16px;
  font-family: $robotoMedium;
}

.full-width {
  width: 100%;
}

.ripple {
  position: relative;
  overflow: hidden;

  &::after {
    display: none;
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100px;
    height: 100px;
    margin-top: -50px;
    margin-left: -50px;
    top: 50%;
    left: 50%;
    animation: ripple 1.5s;
    opacity: 0;
  }

  &:focus:not(:active)::after {
    display: block;
  }
}

.no-radius {
  border-radius: 0 !important;
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(10);
  }
}
