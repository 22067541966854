@import "../../assets/scss/variables";

.button {
  min-width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
  gap: 8px;

  &.start {
    justify-content: flex-start;
  }
  &.end {
    justify-content: flex-end;
  }

  &.left {
    flex-direction: row;
  }

  &.right {
    flex-direction: row-reverse;
  }

  //size options
  &.medium {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &.large {
    .h6 {
      font-size: 16px;
    }

    padding-top: 14px;
    padding-bottom: 14px;
  }
  // end size options

  //color options
  &.default {
    background-color: $white;
    color: $black;

    &:hover {
      background-color: $dark06;
    }
  }

  &.primary {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: $primaryDark;
    }
  }

  &.secondary {
    background-color: $white;
    color: $black;
    border: 2px solid $black;

    &:hover {
      background-color: $dark06;
    }
  }

  //end color options

  //button with icon styles
  &.withIcon {
    padding-top: 8px;
    padding-bottom: 8px;

    svg {
      width: $iconSizeM;
      height: $iconSizeM;
    }

    &.primary {
      svg path,
      circle {
        stroke: $white;
      }
    }

    &.secondary,
    &.default {
      svg path,
      circle {
        stroke: $black;
      }
    }

    &.large {
      svg {
        width: $iconSizeL;
        height: $iconSizeL;
      }
    }
  }

  // disabled implementation
  &:disabled {
    cursor: default;

    &.secondary {
      border-color: $dark03;
      color: $dark03;
      svg path,
      circle {
        stroke: $dark03;
      }

      &:hover {
        background-color: $white;
      }
    }

    &.primary {
      background-color: $dark03;
    }
    &.default {
      color: $dark03;
      svg path,
      circle {
        stroke: $dark03;
      }

      &:hover {
        background-color: $white;
      }
    }
  }
}
