@import '../../assets/scss/variables';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.start {
    flex-direction: row;
  }
  &.end {
    flex-direction: row-reverse;
  }
}

.container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  border-radius: 2px;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: $white;
  border: 2px solid $dark03;
  box-sizing: border-box;

  svg {
    display: none;
    transform: translate(-20%, -20%) scale(0.6);
    margin: 2px;
    path,
    circle {
      fill: $white;
      stroke: transparent;
    }
  }
}

.container {
  &:hover {
    input {
      ~ .checkmark {
        background-color: $dark06;
      }

      &:checked:not(:disabled) ~ .checkmark {
        &.default {
          background-color: $black;
          border-color: $black;
        }
        &.primary {
          background-color: $primaryDark;
          border-color: $primaryDark;
        }
      }
    }
  }

  input {
    &:checked {
      ~ .checkmark > svg {
        display: block;
      }

      ~ .checkmark {
        &.default {
          background-color: $black;
          border: 2px solid $black;
        }
        &.primary {
          background-color: $primary;
          border: 2px solid $primary;
        }
      }

      &:disabled ~ .checkmark {
        background-color: $dark03;
        border: 2px solid $dark03;
      }
    }

    ~ .miniBorder {
      border: 1px solid $dark03;
      background-color: $white;
    }

    &:disabled {
      ~ .checkmark {
        cursor: default;
        background-color: $white;
        border: 2px solid $dark05;
      }

      ~ .miniBorder {
        background-color: $white;
        border: 1px solid $dark05;
      }
    }
  }
}
