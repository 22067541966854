@import "../../assets/scss/variables";

.item {
  border-bottom: 1px solid $dark05;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding-bottom: 24px;

  .info {
    display: flex;
    flex-direction: row;
  }

  .thumb {
    border-radius: 8px;
    // height: 96px;
    margin-right: 24px;
    vertical-align: middle;
    width: 96px;
  }

  .data {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: $normal;
    justify-content: flex-end;
    text-align: left;
    width: 100%;

    div {
      min-width: 120px;
      padding-right: 16px;

      &:first-child {
        flex: 1;
      }

      &:last-child {
        min-width: 32px;
        padding-right: 0;
        width: 32px;
      }
    }

    .title {
      font-size: $medium;
    }
  }

  .boxWrapper {
    align-items: center;
    display: none;
    justify-content: center;
    padding: 16px;
    width: 100%;

    &.open {
      display: flex;
    }
  }

  .dropdown {
    align-items: center;
    background-color: $dark06;
    border-radius: 32px;
    display: flex;
    height: 32px;
    justify-content: center;
    width: 32px;

    svg {
      path {
        fill: $dark02;
      }
    }
  }

  button {
    border: none;
    cursor: pointer;
  }
}
