.wrapper {
  height: 100%;
  position: relative;
  width: 100%;

  &:not(.fitHeight) {
    div[class='react-pdf__Document'],
    div[class='react-pdf__Page'] {
      display: block;
      height: 100% !important;
      width: 100% !important;
    }

    &.zeroZoom {
      canvas {
        display: block;
        height: auto !important;
        width: 100% !important;
      }
    }
  }

  &.fitHeight {
    overflow: hidden;

    div[class='react-pdf__Document'],
    div[class='react-pdf__Page'] {
      display: block;
      height: 100% !important;
      width: 100% !important;

      canvas {
        display: block;
        height: auto !important;
        margin: 0 auto;
        max-height: 100% !important;
        max-width: 100% !important;
        width: auto !important;
      }
    }
  }
}

div.fullscreen {
  height: 100%;
}

.doublePage {
  display: flex;
  width: 100%;
}

.landscape {
  width: 100%;
}

.topBorder {
  border-top: 2px solid #f5f5f5;
}

.leftBorder {
  border-left: 2px solid #f5f5f5;
}

.zoomOut {
  display: flex;
}

.bloquedPage {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  height: 100%;
  width: 100%;
}
