@import '../../assets/scss/variables';

.chip {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  padding: 3px;
  color: $dark02;
  gap: 8px;
  align-items: center;
  display: flex;
  border-radius: 16px;
  border: 1px solid $dark05;
  background-color: $white;

  &:hover {
    background-color: $dark06;
  }

  svg {
    rect {
      fill: $dark02;
    }
  }
}
.avatar {
  border: none !important;
  padding: 0 !important;
}
