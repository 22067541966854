@import '../../assets/scss/variables';

.inputContainer {
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  position: relative;

  &.hasError {
    .input {
      border-color: $semantic03;

      &:focus~.label {
        color: $semantic03;
      }
    }

    .label {
      color: $semantic03;
    }

    span {
      color: $semantic03;
    }
  }
}

.selector {
  >div {
    width: auto;
    min-width: 100%;
    outline: none;
    border: none;
    border-color: $dark03;
    border-radius: 4px;
    font-size: 16px;
    font-family: $roboto;
    color: $black;
    
    >div {
      outline: none;
    }
  }
}

.optionalText {
  margin-left: 4px;
}