@import '../../assets/scss/_variables';

.border {
  padding: 1px;
  border-radius: 50%;
  border: 2px solid $black;
}

.borderInfo {
  border-color: $primary;
}

.avatar {
  align-items: center;
  background-color: $white;
  border-radius: 100%;
  border: none;
  color: $white;
  cursor: inherit;
  display: flex;
  font-family: 'Roboto Medium', sans-serif;
  font-weight: 500;
  justify-content: center;
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  &.selectable {
    border: 2px solid;
    box-sizing: content-box;
    padding: 2px;
  }

  &.bg {
    background-color: $corp;
  }

  &.selected {
    border: 2px solid $primary;
    background-clip: content-box;
  }

  &:disabled {
    background-color: $dark05 !important;
    cursor: default;
    .avatarIcon {
      circle,
      path {
        stroke: $dark04 !important;
      }
    }
  }

  &:hover {
    .iconContainerOnHover {
      display: flex !important;
    }
    .imgOnHover {
      filter: brightness(60%);
    }
  }

  &.withIcon {
    background-color: $semantic01b;
  }

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }

  .avatarIcon {
    circle,
    path {
      stroke: $primary;
    }
  }
}

.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24);
  padding: 0px !important;
  border: 0px !important;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -4px;
  right: -2px;
  border-radius: 50%;
  svg {
    path {
      fill: white;
    }
  }
}

.badgeWithBorder {
  top: -7px;
  right: -5px;
}

.iconContainerOnHover {
  display: none !important;
  position: absolute !important;
}

.iconOnHover {
  path {
    stroke: $white !important;
  }
}
.imgDisabled {
  opacity: 0.5;
}
