* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
textarea,
button,
select {
  outline: none;
}
