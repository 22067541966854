@import '../../assets/scss/variables';

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;

  .content {
    display: none;

    &:not(.clickable) {
      display: none;
    }

    &:not(.disabled).display {
      display: block;
    }

    position: absolute;
    background-color: $white;
    min-width: 180px;
    box-shadow: $elevation2;
    padding: 4px 0;
    z-index: 100;
    border-radius: 4px;
    right: 0;
  }

  &:hover .content {
    &:not(.disabled):not(.clickable) {
      display: block;
    }
  }
}

.item {
  align-items: center;
  color: $black;
  cursor: pointer;
  font-size: 14px;
  min-height: 40px;
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  padding: 4px 24px;
  gap: 8px;
  // .itemIcon {
  //   padding-right: 12px;
  // }

  &:visited {
    color: $black;
  }

  &.active {
    // color: $corp;
    padding-left: 16px;

    // svg path,
    // circle {
    //   stroke: $corp;
    // }
  }

  &:hover {
    background-color: $dark06;
  }

  .subtitle {
    color: $dark03;
    font-size: $xsmall;
    padding: 4px 0;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
