.checkMarkStyleSm{
  width: 16px;
  height: 16px;
  svg {
    transform: translate(-28%, -28%) scale(0.5);
  }
}

.checkMarkStyleMd{
  width: 20px;
  height: 20px;
  svg {
    transform: translate(-20%, -20%) scale(0.6);
  }
}