// Color Definition
$corp: #18c6b5;
$primary: #14a395;
$primaryDark: #108478;

$black: #333;
$dark02: #757575;
$dark03: #949494;
$dark04: #ccc;
$dark05: #e5e5e5;
$dark06: #f5f5f5;
$white: #fff;

$stage01: #f9745f;
$stage02: #6ecbe0;
$stage03: #ffc74a;

$semantic01: #14a395;
$semantic02: #f90;
$semantic03: #e8304f;
$semantic04: #006aff;

$semantic01b: #d9f0ee;
$semantic02b: #ffefd6;
$semantic03b: #fbdee3;
$semantic04b: #d6e7ff;

$comp01: #73affb;
$comp02: #5b81b6;

$pink: #e061eb;
$orange: #ff9750;
$blue: #5fa8ff;

// Font Sizes
$xlarge: 48px;
$large: 32px;
$caption: 24px;
$medium: 20px;
$normal: 16px;
$small: 14px;
$xsmall: 12px;
$micro: 10px;

// Font family
$roboto: 'Roboto', sans-serif;
$robotoMedium: 'Roboto Medium', sans-serif;
$rubik: 'Rubik Regular', sans-serif;
$rubikMedium: 'Rubik Medium', sans-serif;
$rubikSemibold: 'Rubik Semibold', sans-serif;

// Global sizes
$topBarHeight: 72px;
$sideBarWidth: 232px;
$iconSizeM: 24px;
$iconSizeL: 32px;

$elevation1: 0 2px 4px rgba(0, 0, 0, 0.24);
$elevation2: 0 4px 8px rgba(0, 0, 0, 0.24);
$elevation3: 0 8px 16px rgba(0, 0, 0, 0.24);
