.option {
  span {
    font-size: 14px;
    margin-left: 5px;
  }
}

.icon {
  margin-left: 8px;
}
