@import '../../../../../../innova-components/src/lib/assets/scss/_variables.scss';
@import '../../assets/styles/variables';

@mixin hoverSlider {
  .arrow {
    display: flex;
  }
}

.subtitle {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding-bottom: 16px;
  position: relative;
  border-bottom: 1px solid #e5e5e5;

  @media screen and (max-width: 815px) {
    @include hoverSlider;
  }

  &:hover {
    @include hoverSlider;
  }
}

.arrow {
  display: none;
  position: absolute;
  z-index: 2;

  button {
    background-color: $dark06 !important;
  }
}

.arrowIcon {
  path {
    stroke: $black !important;
  }
}

.iconContainer {
  height: 16px !important;
  width: 16px !important;
}

.sliderScroll {
  > div {
    overflow-x: scroll;
  }
}

.slider {
  > div > div {
    margin: 0;
    padding-top: 12px;
    padding-bottom: 16px;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.sliderFix {
  margin-bottom: 8px;
}

.sliderItem {
  width: 100%;

  img {
    aspect-ratio: 16 / 9;
  }
}

.slick-slider {
  &:hover {
    .slick-prev,
    .slick-next {
      opacity: 1;
    }
  }
}

.slick-list {
  height: auto;
  padding-top: 12px;
  padding-bottom: 16px;
  overflow: visible;
}

.slick-prev,
.slick-next {
  opacity: 0;
  transition: 0.15s all;
  position: absolute;
  top: 25%;
  z-index: 10;

  // @media screen and (max-width: $burgerMenuBreakpoint) {
  //   opacity: 1;
  // }
}

.slick-prev {
  left: 0;
  margin-left: -24px;
}

.slick-next {
  right: 0;
  margin-right: -8px;
}

.slick-disabled {
  display: none;
  pointer-events: none;
}

.slick-track {
  margin: 0;

  // remove padding-right of last session on the list, className is resourceBox in case you want to search for it
  > div:last-child {
    > div {
      > div {
        > div {
          padding-right: 24px;

          > div {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.showSlider {
  .slick-slide:not(.slick-active) {
    opacity: 0.25;
    pointer-events: none;
  }
}

.showMoreSlider {
  .slick-slide:not(.slick-active) {
    opacity: 0;
  }
}
