@import '../../assets/scss/variables';

.inputContainer {
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  position: relative;

  .input {
    width: 100%;
    outline: none;
    border: 1px solid;
    border-color: $dark03;
    border-radius: 4px;
    font-size: 16px;
    font-family: $roboto;
    color: $black;
    padding: 8px;
    // check all line-heights;

    &.iconleft {
      padding-left: 32px;
    }

    &.iconright {
      padding-right: 32px;
    }

    &:focus {
      border-color: $primary;
    }

    &:focus ~ .label {
      color: $primary;
    }

    &::placeholder {
      color: $dark03;
      opacity: 1;
    }
  }

  &.hasError {
    .input {
      border-color: $semantic03;

      &:focus ~ .label {
        color: $semantic03;
      }
    }

    .label {
      color: $semantic03;
    }

    span {
      color: $semantic03;
    }
  }
}

.iconContainer {
  position: absolute !important;
  top: 5px;
  padding: 6px;

  &:hover {
    background-color: #33333310;
    border-radius: 3.25px;
  }

  svg {
    path {
      stroke: $dark02;
    }
  }

  &.left {
    left: 8px;
  }

  &.right {
    right: 8px;
  }

  &.withLabel {
    top: 32px;
  }
}

.clickable {
  cursor: pointer;
}

.optionalText {
  margin-left: 4px;
}
