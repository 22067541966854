@import-normalize;
@import 'libs/innova-components/src/lib/assets/scss/_global.scss';
@import 'fonts';

html,
body {
  margin: 0;
  overflow: hidden;
  padding: 0;
}

$app-height: calc(var(--vh, 1vh) * 100);
