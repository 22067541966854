@import "../../assets/scss/variables";

.toggleContainer {
  position: relative;
}

.toggleInput {
  opacity: 0;
  z-index: 1;
  border-radius: 100px;
  width: 48px;
  height: 24px;

  &:checked ~ .toggleLabel {
    background: $primary;

    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin-left: 26px;
      transition: 0.2s;
    }
  }
  &:disabled ~ .toggleLabel {
    background: $dark05;
    cursor: default;
  }
  &:checked:disabled ~ .toggleLabel {
    background: $semantic01b;
  }
}

.toggleLabel {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 24px;
  border-radius: 100px;
  background: #bebebe;
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 2px;
    background: #fff;
    transition: 0.2s;
  }
}
