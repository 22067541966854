@import "../../assets/scss/variables";

$height: 32px;
$thumb-height: 16px;
$track-height: 4px;

// colours
$upper-color: $dark05;
$lower-color: $primary;
$thumb-color: $primary;
$thumb-hover-color: $primary;
$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $lower-color, $lower-color) 100% 50% / 100% $track-height no-repeat transparent;

@function webkit-slider-thumb-shadow($i: 1) {
  $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};

  @if $i == 1 {
    @for $k from 2 through 1000 {
      $val: #{$val}, webkit-slider-thumb-shadow($k);
    }
  }

  @return $val;
}

.slider {
  appearance: none;
  background-color: inherit;
  cursor: pointer;
  display: block;
  height: $height;
  margin: 0;
  overflow: hidden;
  width: 100%;

  &:focus {
    outline: none;
  }
}

// Webkit
.slider::-webkit-slider-runnable-track {
  background: $lower-background;
  height: $height;
  width: 100%;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  background: $thumb-color;
  border: 0;
  border-radius: 100%;
  box-shadow: webkit-slider-thumb-shadow();
  height: $thumb-height;
  margin-top: (-$thumb-height/2);
  position: relative;
  top: 50%;
  transition: background-color 150ms;
  width: $thumb-height;
}

// Firefox
.slider::-moz-range-track,
.slider::-moz-range-progress {
  background: $upper-background;
  height: $height;
  width: 100%;
}

.slider::-moz-range-progress {
  background: $lower-background;
}

.slider::-moz-range-thumb {
  appearance: none;
  background: $thumb-color;
  border: 0;
  border-radius: 100%;
  height: $thumb-height;
  margin: 0;
  transition: background-color 150ms;
  width: $thumb-height;
}

// Internet Exploder
.slider::-ms-track {
  background: transparent;
  border: 0;
  color: transparent;
  height: $height;
  width: 100%;
}

.slider::-ms-fill-lower {
  background: $lower-background;
}

.slider::-ms-fill-upper {
  background: $upper-background;
}

.slider::-ms-thumb {
  appearance: none;
  background: $thumb-color;
  border: 0;
  border-radius: 100%;
  box-shadow: none;
  height: $thumb-height;
  margin: 0;
  top: 0;
  transition: background-color 150ms;
  width: $thumb-height;
}

.slider:hover,
.slider:focus {
  &::-webkit-slider-thumb {
    background-color: $thumb-hover-color;
  }

  &::-moz-range-thumb {
    background-color: $thumb-hover-color;
  }

  &::-ms-thumb {
    background-color: $thumb-hover-color;
  }
}
