@import '../../assets/scss/variables';

.inputContainer {
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  position: relative;

  &.hasError {
    .inputChips {
      border-color: $semantic03;
    }
    span {
      color: $semantic03;
    }
  }
  .inputChips {
    padding: 7px 8px;
    border: 1px solid;
    border-color: $dark03;
    border-radius: 4px;
    gap: 5px;
    display: flex;
    flex-wrap: wrap;
    min-height: 88px;
    max-height: 50vh;
    overflow: auto;

    &:focus-within {
      border-color: $primary;
    }
  }

  .options {
    max-height: 300px;
    overflow: auto;
    background-color: $white;
    position: absolute;
    width: -webkit-fill-available;
    z-index: 1;
    border-radius: 8px;
    box-shadow: $elevation2;
    .item {
      padding: 8px 16px;
      &:hover {
        background-color: $dark06;
      }
    }
    .noOptions {
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.input {
  padding: 6px;
  resize: none;
  overflow: hidden;
  flex: 1;
  width: 100%;
  color: $black;
  border: none;
  font-size: 14px;
  font-family: $roboto;
  line-height: 20px;

  &:focus div ~ .label {
    color: $primary;
  }

  button {
    flex: 1;
  }

  &::placeholder {
    color: $dark03;
    opacity: 1;
  }
}
