.loader {
  display: flex;
  justify-content: center;
}
.loaderText {
  margin-left: 0.5rem;
}
.subRowTitle {
  margin-bottom: 0.5rem;
}
