@import '../../../assets/scss/variables';

.button {
  position: relative;
  border-radius: 4px;
  outline: 0;
  color: $white;
  background-color: $black;
  border: 2px $black;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  transition: all ease 200ms;
  transition-property: opacity box-shadow color;
  cursor: pointer;
}

.button:hover {
  background-color: $black;
}
.button:active {
  background-color: $black;
}
.button:disabled {
  background-color: $dark03;
  color: $dark05;
  cursor: default;
}
.button.large {
  padding: 14px 16px;
  font-size: 16px;
  line-height: 18px;
  min-height: 40px;
  min-width: 80px;
}

.button.extraLarge {
  padding: 14px 16px;
  font-size: 24px;
  line-height: 26px;
}

.button.secondary {
  background-color: transparent;
  color: $black;
  box-shadow: inset 0px 0px 0px 2px $black;
}

.button.secondary:hover {
  box-shadow: inset 0px 0px 0px 3px $black;
}
.button.secondary:active {
  box-shadow: inset 0px 0px 0px 3px $black;
  color: $black;
}
.button.secondary:disabled {
  box-shadow: inset 0px 0px 0px 2px $dark03;
  color: $dark03;
}

.arrow {
  width: 20px;
  height: 20px;
  opacity: 0;
  right: -8px;
  position: absolute;
  transition: all ease 400ms;
}

.button.clear {
  background-color: transparent;
  color: $black;
}

.button.clear:hover {
  box-shadow: none;
}

.button.clear:hover + .arrow {
  right: -16px;
  opacity: 1;
}

.arrowHover {
  right: -16px !important;
  opacity: 1 !important;
}

.button.clear:active + .arrow {
  right: -18px;
  opacity: 1;
}

.button.clear:disabled {
  color: $dark03;
}

.button.icon {
  padding: 0px;
  background-color: transparent;
}

.button.icon:hover {
  opacity: 0.6;
}

.container {
  position: relative;
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 770px) {
  .button.arrowMobile + .arrow {
    right: -16px;
    opacity: 1;
  }
}
