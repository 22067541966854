@import '../../assets/scss/variables';

.logo {
  display: flex;
  align-items: center;
  gap: 16px;

  h5 {
    color: $primary;
  }

  img {
    height: 24px;
  }
}
